.ant-layout-header{
    width:100%;
    height: 70px;
    background: url("../images/nav-bg.png")no-repeat;
    background-size: cover;
    padding: 0;
    line-height: 70px;
    overflow: hidden;
    position: fixed;
    top:0;
    z-index:99;
}
.slider{
    width: 100%;
    height: 100%;
    margin: 0 auto;
}
.logo,.nav,.phone{
    display: inline-block;
    vertical-align: middle;
}
.nav{
    width: 840px;
    height: 100%;
    font-size: 16px;
    color:#fff;
    margin: 0 40px 0 60px;
}
.nav .nav-list-hd{
    width: 126px;
    float: left;
}
.nav .dot{
    position: absolute;
    right: 0;
    top:0;
    z-index:2;
}
.nav-list-hd.active{
    background: url("../images/selected.png")no-repeat;
    background-size: 100% 100%;
}
.nav .nav-item{
    float: left;
    width: 20%;
    position: relative;
}
.logo{
    width: 52px;
    height: 32px;
}
.page-con .bg{
    position: absolute;
    left:50%;
    top:50%;
}
.page-1{
    /*width: 1412px;*/
    /*height: 420px;*/
    /*background: url("../images/page-1.png")no-repeat;*/
    /*background-size: 1412px 420px;*/
    /*background-size: cover;*/
}
.page-1 .bg{
    width: 1411px;
    height: 325px;
    z-index:1;
    margin: -200px 0 0 -606px;
}
.page-2{
    /*width: 1310px;*/
    /*height: 420px;*/
    /*background: url("../images/page-2.png")no-repeat 200px center;*/
    /*background-size: 1310px 420px;*/
    /*background-position: ;*/
}
.page-2 .bg{
    width: 1309px;
    height: 325px;
    margin: -222px 0 0 -504px;
}

.page-3{
    /*width: 1411px;*/
    /*height: 420px;*/
    /*background: url("../images/page-3.png")no-repeat;*/
    /*background-size: 1411px 420px;*/
    /*!*background-size: cover;*!*/
}
.page-3 .bg{
    width: 1411px;
    height: 325px;
    margin: -220px 0 0 -700px;
}
.swiper-container{
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
}
.page-con{
    width: 100%;
    height: auto;
    margin: 100px auto 0 auto;
}
.page-con .bg img{
    width: 100%;
    height: 100%;
}
.page-con .text{
    position: relative;
    z-index:2;
}
.page-con .text-1{
    width: 985px;
    height: 295px;
    margin: 0 auto;
}
.page-con .text-2{
    width: 640px;
    height: 294px;
    margin: 0 auto;
}
.page-con .text-3{
    width: 976px;
    height: 294px;
    margin: 0 auto;
}
.know-us{
    width: 328px;
    height: 70px;
    margin: 20px auto 0 auto;
}
.mouse{
    width: 24px;
    height: 36px;
    margin: 100px auto 0 auto;
}
@media screen and (max-width: 1280px){
    .slider{
        width: 1024px;
        height: 100%;
    }
    /*.logo{*/
        /*width: 120px;*/
        /*height: 26px;*/
    /*}*/
    .nav{
        width: 700px;
        margin: 0 20px 0 60px;
    }
    .nav .nav-list-hd{
        font-size: 14px;
    }
    .phone{
        width: 120px;
    }
}
.ant-layout-content{
    padding-top: 70px;
}
.home-bg{
    width: 1259px;
    height: 624px;
    background: url("../images/sjx.png");
    background-size: 100% 100%;
    position: absolute;
    top:100px;
    left:50%;
    margin-left: -628px;
}
