.detail-hd {
  width: 100%;
  margin-top: -90px;
}

.hd {
  width: 1260px;
  margin: 0 auto;
  position: relative;
  z-index: 9
}

.detail-nav {
  display: flex;
  justify-content: center;
  height: 70px;
  line-height: 70px;
  overflow: hidden;
  cursor: pointer;
}

.nav-list {
  width: 230px;
  font-size: 24px;
  color: #fff;
  float: left;
}

.case-list-pc .case-item {
  width: 1110px;
  height: 756px;
  cursor: pointer;
}



/* .case-list-pc .case-item .img-con {
  background: none !important;
} */



.nav-list.active {
  background: url("http://sf.qianhai12315.com/test_zs_web/static/images/nav-bg.png")no-repeat;
  background-size: 100% 100%;
}

@media screen and (max-width: 1280px) {

  /*.detail .case-con{*/
  /*width: 1024px;*/
  /*}*/
  .case-con .case-list {
    margin: 0 28px 30px 0;
  }
}