body,html,#root{
  width: 100%;
  height: 100%;
}
.App {
  text-align: center;
  width:100%;
  min-height: 100%;
  background: url("./images/bg.jpg")no-repeat;
  background-size:cover;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-bottom: 60px;
  position: relative;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
/* CSS Document */
html, body, div, span, object, iframe,h1, h2,
h3, h4, h5, h6, p, blockquote, pre,abbr, address, cite, code,del, dfn,
em, img, ins,kbd, q, samp,small, strong, sub, sup, var,b, i,dl, dt, dd,
ol, ul, li,fieldset, form, label, legend,table, caption, tbody,
tfoot,thead,tr, th, td,article, aside, canvas, details, figcaption,
figure, footer, header, hgroup, menu, nav, section, summary,time, mark,
audio, video {
  margin:0;
  padding:0;
  border:0;
  outline:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
  outline-style:none;/*FF*/
}

body {
  line-height:1;
  font-family: "Microsoft YaHei",'宋体';
  font-size: 14px;
}
input{
  padding: 0;
  margin: 0;
}
a{
  margin:0;
  padding:0;
  border:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
  color:#fff;

}
a:hover,a:focus{
  text-decoration:none;
  outline-style:none;/*FF*/
}
table {
  border-collapse:collapse;
  border-spacing:0;
}

input, select {
  vertical-align:middle;
  outline: none;
}
ul, ol { list-style: none; }

/*css为clearfix，清除浮动*/
.clearfix::before,
.clearfix::after{
  content: "";
  height: 0;
  line-height: 0;
  display: block;
  visibility: hidden;
  clear: both;
}
.clearfix:after{clear:both;}
.clearfix{
  *zoom:1;/*IE/7/6*/
}
button{
  outline: none;
}
.btn{
  border: 0;
  background: none;
  padding: 0;
  margin: 0;
}
.btn img{
  width: 100%;
  height: 100%;
}
input{
  border: 0;
  background: none;
}
textarea{
  border: 0;
  background: none;
}
.center{
  position: absolute;
  width: 100%;
  height: auto;
  top:50%;
  left: 0;
  -webkit-transform: translate(0,-50%);
  -moz-transform: translate(0,-50%);
  -ms-transform: translate(0,-50%);
  -o-transform: translate(0,-50%);
  transform: translate(0,-50%);

}
.home-center.center{
  margin-top: -0.7rem;
}
img{
  width: 100%;
  display: block;
  height: auto;
}
.ant-layout{
  background: initial;
}


.case-con{
  width: 1110px;
  margin: 30px auto;
  overflow: hidden;
}
.case-con .case-list:nth-child(5n){
  margin: 0 0 30px 0;
}
.case-list {
  width: 198px;
  height: auto;
  position: relative;
  margin:0 30px 30px 0;
  float: left;
}
.case-list .pic {
  width: 100%;
  height: auto;
  position: absolute;
  top:0;
  left:0;
  z-index:4
}
.case-item{
  width: 190px;
  height: 340px;
  margin: 12px auto 0 8px;
  /*-webkit-border-radius: 14px;*/
  /*-moz-border-radius: 14px;*/
  /*border-radius: 14px;*/
  overflow: hidden;
  position: relative;
  background: url("./images/item-bg.png")no-repeat;
  background-size: 100% 100%;
  padding:4px 1px 2px 1px ;
  overflow: hidden;
}
.case-item .code{
  padding: 10px;
  background: #fff;
}
.img-con{
  width: 100%;
  height: 100%;
  border: 1px solid rgb(98, 221, 251);
}
.img-con img{
  width: 100%;
  height: 100%;
}
.case-name{
  text-align: center;
  font-size:30px;
  line-height: 36px;
  height: 72px;
  color: rgb(255, 255, 255);
  margin-top: 18px;
  overflow: hidden;
}
.tip{
  text-align: center;
  color:#fff;
  position: absolute;
  width: 100%;
  bottom:20px;
  left:0;
}